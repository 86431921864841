import Link from "next/link";
import {useEffect, useState} from "react";

function GDPRMsg() {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const storedValue = localStorage.getItem("gdprMsgConfirmed");

    if (!storedValue) {
      setVisible(true);
    }
  }, []);

  if (!visible) {
    return null;
  }

  const btnHandler = () => {
    setVisible(false);
    localStorage.setItem("gdprMsgConfirmed", "true");
  }

  return (
    <div className="gdpr-msg">
      <p>Denna webbplats använder cookies och liknande tekniker för att erbjuda en så bra användarupplevelse som möjligt. Du kan läsa mer om hur vi hanterar cookies och dina uppgifter i vår <Link href="/integritetspolicy">Integritetspolicy</Link>.</p>
      <button type="button" onClick={btnHandler}>OK</button>
    </div>
  )
}

export default GDPRMsg;
