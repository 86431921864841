import {AnyAction, createSlice} from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { AppThunk } from '../store';
import { createClient } from 'contentful';
import axios from 'axios';

const servicesSlice = createSlice({
  name: 'main',
  initialState: {
    entries: [],
    assets: [],
    objects: [],
    soldObjects: [],
    associationTypes: [],
    coaAssociationTypes: [],
    municipalities: [],
    houseTypes: [],
    biddingInfo: [],
    objectSlugs: []
  },
  reducers: {
    setEntries: (state, action) => {
      return {
        ...state,
        entries: action.payload,
      };
    },
    setAssets: (state, action) => {
      return {
        ...state,
        assets: action.payload,
      };
    },
    setObjects: (state, action) => {
      return {
        ...state,
        objects: action.payload,
      };
    },
    setSoldObjects: (state, action) => {
      return {
        ...state,
        soldObjects: action.payload,
      };
    },
    setAssociationTypes: (state, action) => {
      return {
        ...state,
        associationTypes: action.payload,
      };
    },
    setCoaAssociationTypes: (state, action) => {
      return {
        ...state,
        coaAssociationTypes: action.payload,
      };
    },
    setMunicipalities: (state, action) => {
      return {
        ...state,
        municipalities: action.payload,
      };
    },
    setHouseTypes: (state, action) => {
      return {
        ...state,
        houseTypes: action.payload,
      };
    },
    setBiddingInfo: (state, action) => {
      return {
        ...state,
        biddingInfo: action.payload,
      };
    },
    setObjectSlugs: (state, action) => {
      return {
        ...state,
        objectSlugs: action.payload,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(HYDRATE, (state, action: AnyAction) => {
        return {
          ...state,
          ...action.payload.main
        }
      })
  }
});

export const {
  setEntries,
  setAssets,
  setObjects,
  setSoldObjects,
  setAssociationTypes,
  setCoaAssociationTypes,
  setMunicipalities,
  setHouseTypes,
  setBiddingInfo,
  setObjectSlugs
} = servicesSlice.actions;

const client = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
});

export const fetchEntries = (): AppThunk => async (dispatch) => {
  const query = {
    content_type: "page",
    include: 10
  };

  const query2 = {
    "content_type": "testimonial",
    include: 10
  }

  const res = await Promise.all([
    client.getEntries(query),
    client.getEntries(query2)
  ]);

  const entries = [
    ...res[0].items,
    ...res[0].includes.Entry,
    ...res[1].items
  ];

  dispatch(setEntries(entries));
};

export const fetchPageBySlug = slug => async (dispatch) => {
  const promises = []

  // The front page has no slug, querying for pages
  // with slug='' would return all pages, which
  // we don't want
  if (slug) {
    promises.push(client.getEntries({
      "content_type": "page",
      "fields.slug": slug,
      "include": 10
    }))
  }

  if (!slug) {
    promises.push(client.getEntries({
      "content_type": "page",
      "fields.isHomePage": true,
      "include": 5
    }))
  }

  promises.push(client.getEntries({
    "content_type": "testimonial"
  }))

  promises.push(client.getEntries({
    "content_type": "customerMessage"
  }))

  const res = await Promise.all(promises)

  let entries = []

  res.forEach(res => {
    if (res.items) {
      entries = [...entries, ...res.items]
    }
    if (res.includes && res.includes.Entry) {
      entries = [...entries, ...res.includes.Entry]
    }
  })

  dispatch(setEntries(entries))
}

export const fetchDictionaryWordPageBySlug = slug => async (dispatch) => {
  const query = {
    "content_type": "dictionaryWordPage",
    "fields.slug": slug
  };

  const res = await client.getEntries(query);

  dispatch(setEntries([
    ...res.items
  ]));
}

export const fetchLocalLandingPageBySlug = slug => async (dispatch) => {
  const query = {
    "content_type": "localLandingPage",
    "fields.slug": slug,
    "include": 10
  };

  // TODO: Because the component expects this particular CF entry. Place this block on the LLP entry in Contentful instead.
  const query2 = {
    "sys.id": "7zuf7mdcAgJVaOhLh0479u"
  };

  const query3 = {
    "content_type": "testimonial"
  }

  const res = await Promise.all([client.getEntries(query), client.getEntries(query2), client.getEntries(query3)]);

  dispatch(setEntries([
    ...res[0].items,
    ...(res[0].includes && res[0].includes.Entry ? res[0].includes.Entry : []),
    ...res[1].items,
    ...(res[1].includes && res[1].includes.Entry ? res[1].includes.Entry : []),
    ...res[2].items,
    ...(res[2].includes && res[2].includes.Entry ? res[2].includes.Entry : [])
  ]));
}

export const fetchAssets = (): AppThunk => async (dispatch) => {
  const query = {
    limit: 1000,
  };

  const assets = await client.getAssets(query);
  dispatch(setAssets(assets.items));
};

export const fetchObjects = (): AppThunk => async (dispatch) => {
  const fetchObjects = axios.get(`${process.env.NEXT_PUBLIC_OBJECTS_URL}`);
  const fetchSoldObjects = axios.get(`${process.env.NEXT_PUBLIC_SOLD_OBJECTS_URL}`);
  const fetchAssociationTypes = axios.get(
    `https://static2.privatmaklaren.se/db/houseAssociationTypes.json`
  );
  const fetchCoaAssociationTypes = axios.get(
    `https://static2.privatmaklaren.se/db/coaAssociationTypes.json`
  );
  const fetchMunicipalities = axios.get(
    `https://static2.privatmaklaren.se/db/municipalities.json`
  );
  const fetchHouseTypes = axios.get(
    `https://static2.privatmaklaren.se/db/houseTypes.json`
  );
  const res = await Promise.all([
    fetchObjects,
    fetchAssociationTypes,
    fetchMunicipalities,
    fetchHouseTypes,
    fetchCoaAssociationTypes,
    fetchSoldObjects
  ]);
  dispatch(setObjects(res[0].data));
  dispatch(setAssociationTypes(res[1].data));
  dispatch(setMunicipalities(res[2].data));
  dispatch(setHouseTypes(res[3].data));
  dispatch(setCoaAssociationTypes(res[4].data));
  dispatch(setSoldObjects(res[5].data));

  const slugs = res[0].data.map((item) => `/${item.Slug}`);
  dispatch(setObjectSlugs(slugs));
};

export const fetchBiddingInfo = (): AppThunk => async (dispatch) => {
  const res = await axios.get(
    `${process.env.NEXT_PUBLIC_API_ENDPOINT}v2/bidding/info`
  );
  dispatch(setBiddingInfo(res.data));
};

export default servicesSlice;
